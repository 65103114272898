<div class="flex align-items-center justify-content-between px-5 h-screen">
  <div class="flex-1 p-5 flex flex-column justify-content-between">
    <img [src]="'assets/icons/' + logo" height="60px" width="60px" class="mb-5" />
    <div class="text-8xl text-primary-400 font-bold mb-5">404</div>
    <div class="text-xl text-primary-400 font-bold mb-3">
      Houston, we have a problem!
    </div>
    <div class="mb-3">
      In this vast part of the internet universe, you have collided with nothing
      but darkness and silence.
    </div>
    <div class="mb-5">Don’t worry, we are here! The humanity needs you.</div>
    <div class="flex">
      <button
        pButton
        class="p-button-text border-round mr-1 shadow-2"
        icon="pi pi-arrow-left"
        label="Go Back"
        (click)="onBack()"
      ></button>
      <button
        pButton
        class="border-round ml-1 bg-primary-400"
        icon="pi pi-left-arrow"
        label="Take Me Home"
        [routerLink]="'/home'"
      ></button>
    </div>
  </div>
  <div class="flex-1 p-5 align-self-center img-ctn">
    <img src="assets/images/you-are-lost.svg" />
  </div>
</div>
