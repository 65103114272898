/* eslint-disable implicit-arrow-linebreak */
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { RefreshTokenGuard } from './guards/refresh-token.guard';
import { PageNotFoundComponent } from './modules/shell/page-not-found/page-not-found.component';

const options: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/shell/shell.module').then((m) => m.ShellModule),
    canActivate: [AuthGuard, RefreshTokenGuard],
  },
  { path: 'unauthorized', component: PageNotFoundComponent },
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: 'on-boarding',
    loadChildren: () =>
      import('./modules/on-boarding/on-boarding.module').then(
        (m) => m.OnBoardingModule
      ),
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./modules/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: '**',
    redirectTo: 'unauthorized',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
