import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { CountriesService } from './@shared/services/countries.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated: boolean = false;
  isInitialized = false;
  constructor(private primengConfig: PrimeNGConfig, private countriesService: CountriesService) {}

  ngOnInit(): void {
    this.enableRipple();
    this.appInitializing();
  }


  async appInitializing() {
    if (this.isInitialized) return;
    try {
      await this.countriesService.setDefaultCountry();
    } catch (error) {
      console.error(error);
    }
    this.isInitialized = true;
  }

  enableRipple() {
    this.primengConfig.ripple = true;
  }
}
