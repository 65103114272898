import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseStore } from './store-pattern/base-store.service';
import { CitiesService, ICity } from './cities.service';
import { environment } from '@env/environment';
import { CredentialsService } from '@app/modules/authentication/credentials.service';
import {ILocationSetting} from "@app/modules/shift-types/models/location-setting/ilocation-setting";
export interface ICountry {
  name?: string;
  code?: string;
  id?: number;
  dial_code?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountriesService extends BaseStore<ICountry[]> {
  static countrySettings = {
    defaultCountryCode: environment.defaultCountryCode,
    ...environment.countrySettings,
  };

  public static setLocationSetting(setting: ILocationSetting) {
    this.countrySettings.defaultLatitude = setting.lat;
    this.countrySettings.defaultLongitude = setting.lng;
  }

  static setSettings(
    country: ICountry,
    cities: ICity[],
    object: any = environment.countrySettings
  ) {
    this.countrySettings = {
      ...object,
      defaultCountryCode: country.code?.toLowerCase() ?? 'sa',
      country: country.name ?? object.country ?? 'Saudi Arabia',
      countryCode: country.dial_code ?? object.countryCode ?? '+966',
      cities,
      defaultLatitude: this.countrySettings.defaultLatitude,
      defaultLongitude: this.countrySettings.defaultLongitude
    };
  }

  constructor(httpClient: HttpClient, private citiesService: CitiesService,
     credentialsService: CredentialsService,
    ) {
    super(httpClient, credentialsService, {
      baseUrl: '/org/merchant/PickupLocation',
      getUrl: '/countries',
    });
  }

  get location(): ILocationSetting {
    return {
      lat: CountriesService.countrySettings.defaultLatitude,
      lng: CountriesService.countrySettings.defaultLongitude,
    };
  }

  setLocation(setting: ILocationSetting,
              callback: Function = (() => {})) {
    CountriesService.setLocationSetting(setting);
    callback();
  }

  async setDefaultCountry(countryCode: string = environment.defaultCountryCode) {
    const defaultCountryCode = countryCode ?? 'sa';
    await this.init();
    const countries = await firstValueFrom(this.loadDataAnonymously());
    const cities = await firstValueFrom(this.citiesService.getByCountryCode(defaultCountryCode));
    if (countries) {
      const country = countries.find((c) => c.code?.toLowerCase() === defaultCountryCode);
      if (country)
      CountriesService.setSettings(country, cities ?? []);
    }
  }
}
