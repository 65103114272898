import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, share, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Credentials, CredentialsService } from './credentials.service';
import { ApiService } from '@app/@shared/services/api/api.service';
import { Router } from '@angular/router';
import { SignUpConfig } from './models/signup-config';
import { SingupDetails } from './models/signup-details.model';

const routes = {
  signUpConfig: () => '/org/profile/signup-configs',
  registrationOTP: () => '/org/profile/registration-otp',
  verifyRegistrationOTP: () => '/org/profile/verify-registration-otp',
  checkEmailAvailability: () =>
    '/org/profile/signup-validation-checks/email?value=',
  checkCompanyNameAvailability: () =>
    '/org/profile/signup-validation-checks/company-name?value=',
  checkCompanyDomainNameAvailability: () =>
    '/org/profile/signup-validation-checks/domain-prefix?value=',
  signup: () => '/org/profile/signup',
  resendVerificationEmail: () => '/org/profile/resend-email-verification',
  login: () => `/org/profile/login`,
  refreshToken: () => `/org/profile/token`,
  otpPhones: () => `/org/profile/otp-phones`,
  sendOtp: () => `/org/profile/send-otp`,
  verifyOtp: () => `/org/profile/verify-otp`,
  resetPassword: () => `/org/profile/reset-password`,
};

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _token_refreshed = false;
  private refreshTokenRequest: Observable<any> | undefined;
  getOtpedPhoneNumber = new Subject<string>();
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private apiSrvc: ApiService,
    private credentialsService: CredentialsService
  ) {}

  // better to get the supported countries list from an API
  getSupportedCountries(): string[] {
    return ['AE', 'SA', 'ARE', 'SAU'];
  }

  getSignupConfig(): Observable<any> {
    return this.httpClient
      .get<SignUpConfig>(routes.signUpConfig(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  getRegistrationOTP(phoneNumber: string = ''): Observable<any> {
    return this.httpClient
      .post(routes.registrationOTP(), { phoneNumber })
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  verifyRegistrationOTP(
    phoneNumber: string = '',
    otp: string
  ): Observable<any> {
    return this.httpClient
      .post(routes.verifyRegistrationOTP(), { phoneNumber, otp })
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  checkEmailAvailability(email: string): Observable<any> {
    return this.httpClient
      .get(routes.checkEmailAvailability() + encodeURIComponent(email))
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  checkCompanyNameAvailability(companyName: string): Observable<any> {
    return this.httpClient
      .get(routes.checkCompanyNameAvailability() + companyName)
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  checkCompanyDomainNameAvailability(domainName: string): Observable<any> {
    return this.httpClient
      .get(routes.checkCompanyDomainNameAvailability() + domainName)
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  completeSignUpProcess(
    signupDetails: SingupDetails,
    token?: any
  ): Observable<any> {
    const data = new FormData();

    data.append('businessTypeId', signupDetails.businessTypeId + '');
    data.append('environmentTypeId', signupDetails.environmentTypeId + '');
    data.append('planId', signupDetails.planId + '');
    data.append('billingPeriod', signupDetails.billingPeriod + '');
    data.append('companyInfo.name', signupDetails.companyInfo.name);
    data.append('companyInfo.country', signupDetails.companyInfo.country);
    data.append('companyInfo.logo', signupDetails.companyInfo.logo);
    data.append(
      'companyInfo.domainPrefix',
      signupDetails.companyInfo.domainPrefix
    );
    data.append('personalInfo.firstName', signupDetails.personalInfo.firstName);
    data.append('personalInfo.lastName', signupDetails.personalInfo.lastName);
    data.append('personalInfo.email', signupDetails.personalInfo.email);
    data.append(
      'personalInfo.phoneNumber',
      signupDetails.personalInfo.phoneNumber
    );
    data.append('password', signupDetails.personalInfo.password);
    if (signupDetails.billingInfo) {
      data.append('cardToken', signupDetails.billingInfo.cardToken);
      data.append('billingAddress.address', signupDetails.billingInfo.address);
      data.append('billingAddress.street', signupDetails.billingInfo.street);
      data.append(
        'billingAddress.district',
        signupDetails.billingInfo.district
      );
      data.append('billingAddress.city', signupDetails.billingInfo.city);
      data.append('billingAddress.zipCode', signupDetails.billingInfo.zipCode);
      data.append(
        'billingAddress.countryCode',
        signupDetails.billingInfo.countryCode
      );
    }

    const regToken = token
      ? {
          headers: new HttpHeaders({
            Authorization: `Bearer ${token}`,
          }),
        }
      : {};
    return this.httpClient
      .post(routes.signup(), data, regToken)
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  resendVerificationEmail() {
    return this.httpClient
      .post(
        routes.resendVerificationEmail(),
        {},
        {
          headers: new HttpHeaders({
            Authorization: `Bearer ${this.credentialsService.credentials?.token}`,
          }),
        }
      )
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<any> {
    const body = new URLSearchParams();
    body.set('username', context.username);
    body.set('password', context.password);

    return this.httpClient
      .post(routes.login(), body.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  getUserAllPhoneNumbers(otpToken: string): Observable<any> {
    return this.httpClient
      .get(routes.otpPhones(), {
        headers: new HttpHeaders({
          Authorization: `Bearer ${otpToken}`,
        }),
      })
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  sendOtp(otpToken: string, phone: string) {
    return this.httpClient
      .post(
        routes.sendOtp(),
        {
          phoneNumber: phone,
        },
        {
          headers: new HttpHeaders({
            Authorization: `Bearer ${otpToken}`,
          }),
        }
      )
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  verifyOtp(
    otpToken: string,
    code: string,
    phoneNumber: string
  ): Observable<any> {
    return this.httpClient
      .post(
        routes.verifyOtp(),
        {
          phoneNumber,
          otp: code,
        },
        {
          headers: new HttpHeaders({
            Authorization: `Bearer ${otpToken}`,
          }),
        }
      )
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  refreshTokenAsync() {
    if (!this._token_refreshed) {
      // make the observable hot so it won't create a request for each subscription
      if (!this.refreshTokenRequest) {
        let body = {
          refreshToken: this.credentialsService?.credentials?.refreshToken,
        };
        this.refreshTokenRequest = this.httpClient
          .post(routes.refreshToken(), body)
          .pipe(share());
      }

      this.refreshTokenRequest.subscribe(
        (res) => {
          this.updateRefreshedToken(res);
          this._token_refreshed = true;
        },
        (err) => {
          this.logout();
        }
      );

      return this.refreshTokenRequest?.pipe(
        map((data: any) => (data ? true : false))
      );
    }

    return of(true);
  }

  private updateRefreshedToken(res: any) {
    const data: Credentials = {
      companyId: this.credentialsService?.credentials?.companyId!,
      username: this.credentialsService?.credentials?.username!,
      token: res.accessToken,
      refreshToken: res.refreshToken,
      companyName: this.credentialsService?.credentials?.companyName!,
      phoneNumber: this.credentialsService?.credentials?.phoneNumber!,
      email: this.credentialsService?.credentials?.email!,
    };
    this.credentialsService.setCredentials(data);
  }

  resetPassword(body: { emailAddress: string }) {
    return this.httpClient
      .post<{ emailAddress: string }>(routes.resetPassword(), body)
      .pipe(catchError((error) => this.apiSrvc.handleError(error)));
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout() {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    this.router.navigateByUrl('/auth');
  }
}
