import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { ApiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { RefreshTokenGuard } from './guards/refresh-token.guard';
import { take } from 'rxjs';
import { DomainInfo } from './models/domain-info.model';
import { EditorModule } from 'primeng/editor';
import { StorageService } from './@shared/services/storage/storage.service';
import { StorageKey } from './@shared/services/storage/storage-key';
import {ProgressBarModule} from 'primeng/progressbar';
import { CarouselModule } from 'primeng/carousel';
import { DropdownModule } from 'primeng/dropdown';
import { ExcelService } from '@app/@shared/services/export/export.service';

export function initializeApp(
  httpClient: HttpClient,
  storageService: StorageService
) {
  if (!environment.featuresAvailability.domainValidation) return;

  return () =>
    httpClient
      .get<DomainInfo>(environment.apiUrl + 'domain-info')
      .pipe(take(1))
      .subscribe((response: DomainInfo) => {
        if (response.isValidDomain) {
          storageService.setData(StorageKey.domainInfo, response);
        } else if (location.hostname !== 'localhost') {
          window.open(environment.signupURL, '_self');
        }
      });
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    EditorModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    MessageModule,
    ToastModule,
    ProgressBarModule,
    CarouselModule,
    DropdownModule,
  ],
  providers: [
    MessageService,
    DialogService,
    ConfirmationService,
    RefreshTokenGuard,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [HttpClient, StorageService],
    },
    ExcelService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
