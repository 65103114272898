import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  logo: string = environment.logo;
  constructor(private location: Location) {}

  ngOnInit(): void {}

  onBack() {
    this.location.back();
  }
}
