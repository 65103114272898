<router-outlet *ngIf="isInitialized"></router-outlet>
<ng-container *ngIf="!isInitialized">
  <p-progressBar mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
</ng-container>

<p-confirmDialog key="dialog" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [style]="{width: '50vw'}"></p-confirmDialog>
<p-confirmPopup key="popup"></p-confirmPopup>
<p-confirmPopup key="icons-popup" styleClass="icons-popup"></p-confirmPopup>
<p-toast key="toast-center" position="center" [baseZIndex]="5000" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
<p-toast key="toast-top-left" position="top-left" [baseZIndex]="5000" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
<p-toast key="toast-top-right" position="top-right" [baseZIndex]="5000" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
<p-toast key="toast-top-center" position="top-center" [baseZIndex]="5000" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
<p-toast key="toast-bottom-right" position="bottom-right" [baseZIndex]="5000" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
<p-toast key="toast-bottom-left" position="bottom-left" [baseZIndex]="5000" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>
<p-toast key="toast-bottom-center" position="bottom-center" [baseZIndex]="5000" [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>

