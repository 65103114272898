import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/modules/authentication/credentials.service';
import { environment } from '@env/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private credentialsService: CredentialsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.credentialsService.credentials?.token;
    let isVendorApiReq = [environment.apiUrl, environment.transactionsUrl, environment.profileUrl, environment.merchantUrl].some(
      (url) => req.url.includes(url)
    );

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    if (isVendorApiReq) {
      if (!req.headers.get("Authorization")) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${authToken}`,
          },
        });
      }
    }

    // send cloned request with header to the next handler.
    return next.handle(req);
  }
}
