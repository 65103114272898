export const citiesResponse = {
  "countryId": 185,
  "code": "SA",
  "cities": [
      {
          "id": 101,
          "name": "Riyadh"
      },
      {
          "id": 102,
          "name": "Jeddah"
      },
      {
          "id": 103,
          "name": "Ad Dammam"
      },
      {
          "id": 104,
          "name": "Mecca"
      },
      {
          "id": 105,
          "name": "Al Hufuf"
      },
      {
          "id": 106,
          "name": "Al Khobar"
      },
      {
          "id": 107,
          "name": "Al Dhahran"
      },
      {
          "id": 108,
          "name": "Al Qatif"
      },
      {
          "id": 109,
          "name": "Ras Tanurah"
      },
      {
          "id": 110,
          "name": "Al Jubail"
      },
      {
          "id": 111,
          "name": "Al Jubail Industrial"
      },
      {
          "id": 112,
          "name": "Medina"
      },
      {
          "id": 113,
          "name": "At Taif"
      }
  ]
}